<template>
  <div id="daily-fiber">
    <h5>{{ $t('tools.fiber.menu') }}</h5>

    <div class="row mt-3">
      <div class="col-md-12 px-0">
        <b-form-group :label="$t('tools.fiber.dailyIntake')" label-for="edit-kcal">
          <b-input-group :append="$t('tools.append.kcal')">
            <b-form-input id="edit-kcal" v-model="kcal" :placeholder="$t('tools.fiber.placeholder.dailyIntake')"
                          type="text"
                          @input="kcal = replaceCommaWithDot(kcal)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-12 text-right">
        <b-button variant="primary" @click="calculate">{{ $t('tools.fiber.btn') }}</b-button>
      </div>
      <div v-if="result" class="col-md-12 text-center mt-3">
        <h4 v-html="$t('tools.fiber.result',{
       result,
          unit: this.$t('tools.append.g')})"
        />
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      kcal: null,
      result: null
    }
  },
  methods: {
    calculate() {
      this.result = this.kcal / 1000 * 14;
    }
  }
}

</script>
