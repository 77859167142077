<template>
  <div id="gfr-equation">
    <h5>{{ $t('tools.gfr.title') }}</h5>
    <span class="text-muted">{{ $t('tools.gfr.subtitle') }}</span>

    <div class="row mt-2">
      <div class="col-md-12 px-0">
        <b-form-group :label="$t('tools.cole.gender')" label-for="edit-name">
          <b-form-select v-model="gender">
            <option value="M">{{ $t('forms.fields.gender.male') }}</option>
            <option value="F">{{ $t('forms.fields.gender.female') }}</option>
          </b-form-select>
        </b-form-group>

        <b-form-group :label="$t('tools.age')" label-for="edit-age">
          <b-input-group :append="$t('tools.append.age')" >
            <b-form-input id="edit-age" v-model="age" type="text"
                          :placeholder="$t('tools.age')"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group :label="$t('tools.weight')" label-for="edit-g">
          <b-input-group :append="$t('tools.append.weight')" >
            <b-form-input id="edit-g" v-model="kg" type="text"
                          :placeholder="$t('tools.bmi.placeholder.weight')"
                          @input="kg = replaceCommaWithDot(kg)"></b-form-input>
          </b-input-group>
        </b-form-group>


        <b-form-group :label="$t('tools.gfr.kreatin')" label-for="edit-oro">
          <b-input-group :append="$t('tools.append.mg_dl')" >
            <b-form-input id="edit-oro" v-model="oros" type="text"
                          :placeholder="$t('tools.gfr.placeholder.kreatin')"
                          @input="oros = replaceCommaWithDot(oros)"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-12 text-right">
        <b-button variant='primary' @click="calculate">
          {{$t('tools.gfr.btn')}}
        </b-button>
      </div>
      <div v-if="result" class="col-md-12 text-center mt-3">
        <h4><strong>{{ result }}</strong> </h4>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      gender: 'M',
      age: null,
      kcal: null,
      result: null,
      kg: null,
      oros: null
    }
  },
  methods:{
    calculate(){
      let resultTable = [0,1,2,3,4,5].map(x => this.$t(`tools.gfr.results.${x}`));
      let result = ((140-this.age) * this.kg) / (72*this.oros) * (this.gender === 'M' ? 1 : 0.85);
      if(result > 90){
        this.result = resultTable[0];
      }else if(result >= 60){
        this.result = resultTable[1];
      }else if(result >= 45){
        this.result = resultTable[2];
      }else if(result >= 30){
        this.result = resultTable[3];
      }else if(result >=15){
        this.result = resultTable[4];
      }else{
        this.result = resultTable[5];
      }
    }
  }
}

</script>
