import axios from "axios";
import {getSavedState, saveState} from "@utils/util";
import {Constants} from "@utils/constants";

const MIN_CACHE_MINS = 10;

export const state = {
    _athleteStatistics: getSavedState('athletes.statistics'),
    _activeAthletes: getSavedState('athletes.activeAthletes'),
    athleteNames: [],
    lastAthleteCache: null,
    showActiveAthletesModal: false,
    lastUpdated: getSavedState('athletes.lastUpdated'),
    _questionnaireStructure: {
    tabs: ['tab_1', 'tab_2', 'tab_3', 'tab_4', 'tab_5', 'tab_6'],
    tab_1: {
        title: 'Ιατρικό Ιστορικό',
        questions: [
            {
                key: 'q1', type: 'YesNo',
                question: 'Έχετε διαγνωστεί ποτέ με αρτηριακή πίεση;',
                subQuestions:[
                    {key: 'q1_1', type: 'text', question: 'Έτος διάγνωσης'},
                    {key: 'q1_2', type: 'text', question: 'Δεν θυμάμαι, αλλά σίγουρα πριν το'},
                    {key: 'q1_3', type: 'textarea', rows: 3, question: 'Τι είδος αγωγή ακολουθείτε;'},
                    {key: 'q1_4', type: 'YesNo', question: 'Ειδική δίαιτα;'},
                    {key: 'q1_5', type: 'YesNo', question: 'Φάρμακα;'},
                    {key: 'q1_6', type: 'text', question: 'Τύπος και δόση φαρμάκων;'},
                ]
            },
            {
                key: 'q2', type: 'YesNo',
                question: 'Έχετε διαγνωσθεί με αυξημένες τιμές λιπιδίων (χοληστερίνη , LDL-χοληστερίνη ή τριγλυκερίδια);',
                subQuestions:[
                    {key: 'q2_1', type: 'text', question: 'Έτος διάγνωσης'},
                    {key: 'q2_2', type: 'text', question: 'Δεν θυμάμαι, αλλά σίγουρα πριν το'},
                    {key: 'q2_3', type: 'textarea', rows: 3, question: 'Τι είδος αγωγή ακολουθείτε;'},
                    {key: 'q2_4', type: 'YesNo', question: 'Ειδική δίαιτα;'},
                    {key: 'q2_5', type: 'YesNo', question: 'Φάρμακα;'},
                    {key: 'q2_6', type: 'text', question: 'Τύπος και δόση φαρμάκων;'},
                ]
            },
            {
                key: 'q3', type: 'YesNo',
                question: 'Έχετε διαγνωσθεί με σακχαρώδη διαβήτη (τύπου 2);',
                subQuestions:[
                    {key: 'q3_1', type: 'text', question: 'Έτος διάγνωσης'},
                    {key: 'q3_2', type: 'text', question: 'Δεν θυμάμαι, αλλά σίγουρα πριν το'},
                    {key: 'q3_3', type: 'textarea', rows: 3, question: 'Τι είδος αγωγή ακολουθείτε;'},
                    {key: 'q3_4', type: 'YesNo', question: 'Ειδική δίαιτα;'},
                    {key: 'q3_5', type: 'YesNo', question: 'Φάρμακα;'},
                    {key: 'q3_6', type: 'text', question: 'Τύπος και δόση φαρμάκων;'},
                ]
            },
            {
                key: 'q4', type: 'YesNo',
                question: 'Έχετε κάποια διαπιστωμένη διατροφική αλλεργία;',
                subQuestions:[
                    {key: 'q4_1', type: 'text', question: 'Παρακαλώ πείτε μας ποια:'},
                ]
            },
            {
                key: 'q5', type: 'YesNo',
                question: 'Έχετε βιώσει ποτέ στο παρελθόν συμπτώματα αφυδάτωσης; (πονοκέφαλο, ζαλάδα, ναυτία κτλ)',
                subQuestions:[
                    {key: 'q5_1', type: 'YesNo', question: 'Κάνετε κάτι διαφορετικό τώρα για να το αποφύγετε;',
                        subQuestions: [
                            {key: 'q5_1_1', type: 'text', question: 'Εξηγήστε μας τι (π.χ. Αθλητικό ποτό, περισσότερο νερό, ηλεκτρολύτες κ.λπ.)'},
                        ]},
                ]
            },
            {
                key: 'q6', type: 'YesNo',
                question: 'Παίρνετε κάποια φαρμακευτική αγωγή;',
                subQuestions:[
                    {key: 'q6_1', type: 'text', question: 'Αναφέρετε ποιο φάρμακο και για ποιο λόγο:'},
                ]
            },
            {
                key: 'q7', type: 'YesNo',
                question: 'Έχετε κάποιο γαστρεντερικό πρόβλημα;'
            },
        ]
    },
    tab_2:{
        title: 'Χαρακτηριστικά Τρόπου Ζωής',
            questions: [
            {
                key: 'q8', type: 'Options',
                question: 'Είστε καπνιστής (περισσότερο από 1 τσιγάρα ημερησίως το τελευταίο έτος);',
                options: [
                    {value: "1", text: "Ναι"},
                    {value: "0", text: "Όχι, διέκοψα"},
                    { value: "-1", text: "Όχι, δεν έχω καπνίσει ποτέ"}],
                subQuestions:[
                    {key: 'q8_1', type: 'text', question: 'Για πόσα χρόνια', value: "1"},
                    {key: 'q8_2', type: 'text', question: 'Πόσα τσιγάρα κατά μέσο όρο την ημέρα καπνίζετε;', value: "1"},
                    {key: 'q8_3', type: 'text', question: 'Πότε διακόψατε το κάπνισμα; (έτος)', value: "0"}
                ]
            },
            {key: 'q9', type: 'YesNo', question: 'Εκτίθεστε σε καπνό του περιβάλλοντος για >30 λεπτά και >5 ημέρες/εβδομάδα;',},
            {key: 'q10', type: 'text', question: 'Πόσες ώρες ύπνου συμπληρώνετε την ημέρα;'},
            {key: 'q11', type: 'YesNo',
                question: 'Ακολουθείτε κάποια συγκεκριμένη διαιτητική πρακτική; (Χορτοφαγία, Vegan, πρωτεϊνική διατροφή)',
                subQuestions: [
                    {key: 'q11_1', type: 'text', question: 'Παρακαλώ πείτε μας ποια:'}
                ]
            },
            {key: 'q12', type: 'text', question: 'Υπάρχουν κάποιες θρησκευτικές και εθνικές προτιμήσεις;'},
            {key: 'q13', type: 'YesNo', question: 'Θεωρείτε ότι ο κύκλος της περιόδου σας είναι σταθερός;'},


        ]
    },
    tab_3: {
        title: 'Διατροφική Αξιολόγηση',
            questions: [
            {
                key: 'q14', type: 'YesNo', question: 'Έχετε ακολουθήσει στο παρελθόν ειδική δίαιτα;',
                subQuestions: [
                    {
                        key: 'q14_1', type: 'Options', question: 'Η δίαιτα ήταν για:',
                        options: [
                            {value: "1", text: "Απώλεια βάρους"},
                            {value: "2", text: "Ρύθμιση σακχάρου"},
                            { value: "3", text: "Ρύθμιση λιπιδίων"},
                            { value: "4", text: "Ρύθμιση αρτηριακής πίεσης"}
                        ],
                    },
                    {
                        key: 'q14_2', type: 'Options', question: 'Ποιος σας έδωσε τη δίαιτα;',
                        options: [
                            {value: "1", text: "Ιατρός"},
                            {value: "2", text: "Διαιτολόγος"},
                            { value: "3", text: "Φιλικό πρόσωπο"},
                            { value: "4", text: "Την είδα στον τύπο"},
                            { value: "5", text: "Μόνος/η μου"}
                        ],
                    }
                ]
            },
            {
                key: 'q15', type: 'text', question: 'Συνολικά φλιτζάνια καφέ / ημέρα (1 φλιτζάνι = 240 ml)'
            },
            {
                key: 'q16', type: 'text', question: 'Συνολικά ποτήρια νερό / ημέρα (1 ποτήρι = 240 ml)'
            },
            {
                key: 'q17', type: 'notice', text: 'Διατροφικές επιλογές : Συμπληρώστε την συχνότητα που καταναλώνετε τα παρακάτω τρόφιμα.'
            },
            {
                key: 'q17_1', type: 'Options', question: 'Γαλακτοκομικά (Γάλα, τυρί, γιαούρτι, κεφίρ):',
                options: [
                    {value: "1", text: "Καθημερινά"},
                    {value: "2", text: "Μερικές φορές την εβδομάδα"},
                    { value: "3", text: "Σπάνια"},
                    { value: "4", text: "Καθόλου"}
                ],
            },
            {
                key: 'q17_2', type: 'Options', question: 'Κόκκινο κρέας:',
                options: [
                    {value: "1", text: "Καθημερινά"},
                    {value: "2", text: "Μερικές φορές την εβδομάδα"},
                    { value: "3", text: "Σπάνια"},
                    { value: "4", text: "Καθόλου"}
                ],
            },
            {
                key: 'q17_3', type: 'Options', question: 'Ψάρι:',
                options: [
                    {value: "1", text: "Καθημερινά"},
                    {value: "2", text: "Μερικές φορές την εβδομάδα"},
                    { value: "3", text: "Σπάνια"},
                    { value: "4", text: "Καθόλου"}
                ],
            },
            {
                key: 'q17_4', type: 'Options', question: 'Αυγά:',
                options: [
                    {value: "1", text: "Καθημερινά"},
                    {value: "2", text: "Μερικές φορές την εβδομάδα"},
                    { value: "3", text: "Σπάνια"},
                    { value: "4", text: "Καθόλου"}
                ],
            },
            {
                key: 'q17_5', type: 'Options', question: 'Φρούτα:',
                options: [
                    {value: "1", text: "Καθημερινά"},
                    {value: "2", text: "Μερικές φορές την εβδομάδα"},
                    { value: "3", text: "Σπάνια"},
                    { value: "4", text: "Καθόλου"}
                ],
            },
            {
                key: 'q17_6', type: 'Options', question: 'Λαχανικά:',
                options: [
                    {value: "1", text: "Καθημερινά"},
                    {value: "2", text: "Μερικές φορές την εβδομάδα"},
                    { value: "3", text: "Σπάνια"},
                    { value: "4", text: "Καθόλου"}
                ],
            },
            {
                key: 'q17_7', type: 'Options', question: 'Ελαφρία αλκοολούχα ποτά (κρασί, μπύρα):',
                options: [
                    {value: "1", text: "Καθημερινά"},
                    {value: "2", text: "Μερικές φορές την εβδομάδα"},
                    { value: "3", text: "Σπάνια"},
                    { value: "4", text: "Καθόλου"}
                ],
            },
            {
                key: 'q18', type: 'YesNo', question: 'Παίρνετε κάποιο συμπλήρωμα διατροφής ή αναβολικές ουσίες;',
                subQuestions: [
                    {
                        key: 'q18_1', type: 'text', question: 'Αν ναι, ποιό από αυτά; (Shaker πρωτεΐνης/σκόνες, Κρεατίνη, Βιταμίνες, Μέταλλα, Αμινοξέα,\n' +
                            '                                            Αθλητικό ποτό, Αθλητικές μπάρες, HMB, Γλουταμίνη, Βότανα, Γλυκοζαμίνη / Χονδροιτίνη, Εφεδρίνη / λιποδιαλύτες, NO2 κτλ.) '
                    }
                ]
            },
        ]
    },
    tab_4: {
        title: 'Αποτίμηση Διατροφικών Συμπεριφορών',
            questions: [
            {
                key: 'q19', type: 'notice', text: 'Πόσες φορές την εβδομάδα καταναλώνετε;',
            },
            {
                key: 'q19_1', type: 'Options', question: 'Πρωινό:',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q19_2', type: 'Options', question: 'Πρόγευμα:',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q19_3', type: 'Options', question: 'Μεσημεριανό:',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q19_4', type: 'Options', question: 'Απογευματινό:',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q19_5', type: 'Options', question: 'Βραδινό:',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q19_6', type: 'Options', question: 'Κατανάλωση αλκοόλ με το Μεσημεριανό γεύμα;',
                options: [{value: "1", text: "Όχι"}, {value: "2", text: "Κρασί κόκκινο"},
                    { value: "3", text: "Κρασί λευκό"}, { value: "4", text: "Μπύρα"},
                    {value: "5", text: "Άλλο"}
                ],
            },
            {
                key: "q19_8", type: 'text', question: 'Μετά από πόση ώρα από το βραδινό φαγητό κοιμάστε (βραδινό ή προ του ύπνου γεύμα);'
            },
            {
                key: 'q20_1', type: 'Options', question: 'Παραλείπετε κάποιο-α γεύματα λόγω φόρτου εργασίας:',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-3 φορές/μήνα"},
                    { value: "3", text: "2-4 φορές/εβδ."}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q20_2', type: 'Options', question: 'Παραλείπετε κάποιο-α γεύματα γιατί θέλετε να χάσετε βάρος;',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-3 φορές/μήνα"},
                    { value: "3", text: "2-4 φορές/εβδ."}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q20_3', type: 'Options', question: 'Παραλείπετε κάποιο-α γεύματα γιατί απλά εκείνη την ώρα δεν πεινάτε;',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-3 φορές/μήνα"},
                    { value: "3", text: "2-4 φορές/εβδ."}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q20_4', type: 'Options', question: 'Κατανάλωση τροφής μπροστά στην τηλεόραση;',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-3 φορές/μήνα"},
                    { value: "3", text: "2-4 φορές/εβδ."}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q20_5', type: 'Options', question: 'Πόσο συχνά καταναλώνετε κάποιο πιο βαρύ γεύμα που προκαλεί δυσφορία;',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-3 φορές/μήνα"},
                    { value: "3", text: "2-4 φορές/εβδ."}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q21_1', type: 'Options', question: 'Κατανάλωση τροφής κάτω από συνθήκες πίεσης (βιασύνη, άγχος)',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q21_2', type: 'Options', question: 'Κατανάλωση τροφής ενώ ταυτόχρονα εργάζεστε (όχι σε διάλειμμα)',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q21_3', type: 'Options', question: 'Κατανάλωση τροφής ενώ είστε όρθιος ή περπατάτε',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q21_4', type: 'Options', question: 'Εσείς είστε υπεύθυνος-η για την προετοιμασία του φαγητού;',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q21_5', type: 'Options', question: 'Πόσο συχνά τρώτε μόνος-η;',
                options: [{value: "1", text: "Σπάνια"}, {value: "2", text: "1-2 φορές/εβδ."},
                    { value: "3", text: "3-5 φορές/εβδ"}, { value: "4", text: "Σχεδόν κάθε μέρα"}
                ],
            },
            {
                key: 'q21_6', type: 'YesNo', question: 'Προσέχετε την διατροφή σας εκτός αγωνιστικής περιόδου;'
            },
            {
                key: 'q21_7', type: 'textarea', question: 'Τρόφιμα που αποφεύγει;'
            },
            {
                key: 'q21_9', type: 'textarea', question: 'Τρόφιμα που του αρέσουν;'
            },
            {
                key: 'q21_8', type: 'Options', question: 'Πόσα ποτήρια υγρών (νερό, αθλητικό ποτό, χυμό κτλ.) συνολικά πίνετε πριν,\n' +
                    '                                        κατά την διάρκεια και μετά την προπόνηση ή τους αγώνες;',
                options: [{value: "1", text: "Κανένα"}, {value: "2", text: "1-2 ποτήρια"},
                    { value: "3", text: "3-5 ποτήρια"}, { value: "4", text: "Πάνω από 5 ποτήρια"}
                ],
            },

        ]
    },
    tab_5:{
        title: 'Αποτίμηση του Επιπέδου Υγείας',
            questions:[
            {
                key: 'q22_1', type: 'Options', question: 'Πόνος / Δυσφορία',
                options: [
                    {value: "1", text: "Δεν αισθάνομαι καθόλου πόνο ή δυσφορία"},
                    {value: "2", text: "Αισθάνομαι μέτριο πόνο ή δυσφορία"},
                    { value: "3", text: "Αισθάνομαι υπερβολικό πόνο ή δυσφορία"},
                ],
            },
            {
                key: 'q22_2', type: 'Options', question: 'Άγχος / Κατάθλιψη',
                options: [
                    {value: "1", text: "Δεν αισθάνομαι καθόλου άγχος ή κατάθλιψη"},
                    {value: "2", text: "Αισθάνομαι μέτριο άγχος ή κατάθλιψη"},
                    { value: "3", text: "Αισθάνομαι υπερβολικό άγχος ή κατάθλιψη"},
                ],
            },
            {
                key: 'q22_3', type: 'Options', question: 'Συγκριτικά με την κατάσταση της υγείας μου τους τελευταίους 12 μήνες, η σημερινή μου κατάσταση είναι:',
                options: [
                    {value: "1", text: "Καλύτερη"},
                    {value: "2", text: "Παραμένει ίδια"},
                    { value: "3", text: "Χειρότερη"},
                ],
            },
            {
                key: 'q22_4', type: 'text', question: 'Σύμφωνα με την δική σας εκτίμηση, πόσο καλή ή άσχημη είναι η σημερινή κατάσταση της υγείας σας (σε κλίμακα 1-100)',
            },
        ]
    },
    tab_6: {
        title: 'Άσκηση',
            questions: [

            { key: 'q23_1', type: 'textarea', rows: 3, question: 'Γενικά σχόλια:' },
            { key: 'q23_2', type: 'textarea', rows: 3, question: 'Άθλημα:' },
            {
                key: 'q23_3', type: 'Options', question: 'Ένταση:',
                options: [
                    {value: "1", text: "Χαμηλή"},
                    {value: "2", text: "Μεσαία"},
                    { value: "3", text: "Υψηλή"}
                ],
            },
            {
                key: 'q23_4', type: 'Options', question: 'Πρόγραμμα Άσκησης',
                options: [
                    {value: "1", text: "Διαλειμματική"},
                    {value: "2", text: "Συνεχόμενη"}
                ],
            },
            {
                key: 'q23_5', type: 'text', question: 'Διάρκεια σε λεπτά'
            },
            {
                key: 'q23_6', type: 'text', question: 'Συχνότητα Φορές/Ημέρα/Εβδομάδα'
            },
            {
                key: 'q23_7', type:'YesNo', question: 'Κάνετε συχνά ταξίδια και βιώνετε Jetlag;',
                subQuestions: [
                    {
                        key: 'q23_7_1', type: 'text', question: 'Πόσο συχνά;'
                    }
                ]
            },
            {
                key: 'q23_8', type:'YesNo', question: 'Τηρείτε τα γεύματα σας τις ημέρες των ταξιδιών;',
            },
            {
                key: 'q23_9', type:'YesNo', question: 'Το άθλημα σας απαιτεί διαχείριση βάρους;',
            }
        ]
    }
},
    _shortQuestionnaireStructure: {
        tabs: ['tab_0', 'tab_1', 'tab_2'],
        tab_0: {
            title: 'Γενικά',
            questions: [
                {key: 'q1', type :'date', question: 'Ημερομηνία:'},
                {key: 'q2', type :'text', question: 'Συστάθηκε από:'},
                {key: 'q3', type :'text', question: 'Είδος απασχόλησης:'},
                {key: 'q4', type :'text', question: 'Ώρες εργασίας:'},
                {key: 'q5', type :'text', question: 'Με ποιον ζει;'},
                {key: 'q6', type :'textarea',rows: 3, question: 'Λίγα λόγια για εσάς:'},
                {key: 'q10', type: 'Options',
                    question: 'Προηγούμενες αιτίες:',
                    options: [
                        {value: "1", text: "Απώλεια βάρους"},
                        {value: "2", text: "Αύξηση βάρους"},
                        { value: "3", text: "Αθλητική απόδοση"},
                        { value: "4", text: "Βελτίωση διατροφικών συνηθειών"},
                        { value: "5", text: "Άλλο"}
                    ],
                    subQuestions: [
                        {key: 'q8', type: 'textarea', question: 'Τι στόχο έχετε;', value: "5"}
                    ]
                },
                {key: 'q11', type :'text', question: 'Ελάχιστο βάρος μετά τα 18 έτη;'},
                {key: 'q11_1', type :'text', question: 'Και σε ποια ηλικία;'},
                {key: 'q12', type :'text', question: 'Μέγιστο βάρος έως σήμερα;'},
                {key: 'q12_1', type :'text', question: 'Και σε ποια ηλικία;'},
            ]

        },
        tab_1: {
            title: 'Υγεία',
            questions: [
                {
                    key: 'q13', type: 'YesNo', question: 'Στομάχι;',
                    subQuestions: [
                        {key: 'q13_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q14', type: 'YesNo', question: 'Περίοδος;',
                    subQuestions: [
                        {key: 'q14_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q15', type: 'YesNo', question: 'Πονοκεφάλους;',
                    subQuestions: [
                        {key: 'q15_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q16', type: 'YesNo', question: 'Έντερο-δυσπεψίες;',
                    subQuestions: [
                        {key: 'q16_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q17', type: 'YesNo', question: 'Χολή;',
                    subQuestions: [
                        {key: 'q17_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q18', type: 'YesNo', question: 'Αναιμία;',
                    subQuestions: [
                        {key: 'q18_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q19', type: 'YesNo', question: 'Δυσκοιλιότητα;',
                    subQuestions: [
                        {key: 'q19_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q20', type: 'YesNo', question: 'Πίεση;',
                    subQuestions: [
                        {key: 'q20_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q21', type: 'YesNo', question: 'Χοληστερίνη;',
                    subQuestions: [
                        {key: 'q21_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q22', type: 'YesNo', question: 'Ζάχαρο;',
                    subQuestions: [
                        {key: 'q22_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q23', type: 'YesNo', question: 'Θυρεοειδής;',
                    subQuestions: [
                        {key: 'q23_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q24', type: 'YesNo', question: 'Αλλεργίες;',
                    subQuestions: [
                        {key: 'q24_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q25', type: 'YesNo', question: 'Φάρμακα;',
                    subQuestions: [
                        {key: 'q25_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q26', type: 'YesNo', question: 'Καπνίζει;',
                    subQuestions: [
                        {key: 'q26_1', type: 'textarea', question: 'Περιγραφή:'}
                    ]
                },
                {
                    key: 'q27', type: 'text', question: 'Ποιος μαγειρεύει;'
                },
                {
                    key: 'q28', type: 'YesNo', question: 'Τρώει βιαστικά;'
                },
                {
                    key: 'q29', type: 'text', question: 'Τροφές που αποφεύγει;'
                },
                {
                    key: 'q36', type: 'text', question: 'Τροφές που του αρέσουν;'
                }
            ]
        },
        tab_2: {
            title: 'Ανάκληση 24ωρου',
            questions: [
                {key: 'q30', type: 'textarea', rows: 4, question: 'Πρωί'},
                {key: 'q31', type: 'textarea', rows: 4, question: 'Πρόγευμα'},
                {key: 'q32', type: 'textarea', rows: 4, question: 'Μεσημέρι'},
                {key: 'q33', type: 'textarea', rows: 4, question: 'Απόγευμα'},
                {key: 'q34', type: 'textarea', rows: 4, question: 'Βράδυ'},
                {key: 'q35', type: 'textarea', rows: 4, question: 'Σημειώσεις'}
            ]
        }
    },
};

export const getters = {
};

export const mutations = {
    CACHE_ACTIVE_ATHLETES(state, athletes){
        state._activeAthletes = athletes;
        saveState('athletes.activeAthletes', athletes);
    },

    CACHE_STATISTICS(state, statistics){
        state._athleteStatistics = statistics;
    },

    CACHE_STATISTICS_TIME(state, time){
        state.lastUpdated = time;
    },

    SET_SHOW_ACTIVE_ATHLETES(state, val) {
        state.showActiveAthletesModal = val;
    },

    CACHE_ATHLETE_NAMES(state, names){
        state.athleteNames = names;
        state.lastAthleteCache = new Date();
    },
    CACHE_LAST_ATHLETE_CACHE(state, date){
        state.lastAthleteCache = date;
    }
};

export const actions = {
    cacheAthleteNames({ state, commit }, force = false) {
        if (!force && state.athleteNames.length > 0 &&
            minutesBetween(new Date(), new Date(state.lastAthleteCache)) < MIN_CACHE_MINS) return;

        axios.get(`${Constants.apiUrl}/athlete/athlete-names`).then(result => {
            commit('CACHE_ATHLETE_NAMES', result.data.athletes.rows.map(x => {
                x.item_data = x.firstName + ' ' + x.lastName;
                return x;
            }));
        }).catch( () => {});
    },

    cacheActiveAthletes({ state, commit }, force = false){
        // avoid fetching data if it was fetched in the last 10 minutes
        if (
            !force
            && state._activeAthletes
            && minutesBetween(new Date(), new Date(state._activeAthletes.lastUpdate)) < MIN_CACHE_MINS
        ) return;

        axios.get(`${Constants.apiUrl}/statistics/active-customers`).then(res => {
            const { activeUserIds, semiActiveUserIds } = res.data;
            commit('CACHE_ACTIVE_ATHLETES', {
                activeUserIds,
                semiActiveUserIds,
                lastUpdate: new Date()
            });
        }).catch(e => {
            console.error(e);
        });
    },

    cacheStatistics({ commit }){
        let statistics = {
            labels: [],
            athletes: {
                maleCount: 0,
                femaleCount: 0,
                newCount: [],
                lastMonthCount: [],
            },
            dietPlans: {
                newCount: 0,
                lastMonthCount: 0,
                totalCount: 0
            },
            earnings:{
                monthlyUntilToday: 0,
                expectedTillEndOfMonth: 0
            },
            events:{
                active: 0,
                cancelled: 0
            }
        };

        return axios.get(process.env.VUE_APP_API + `/athlete/statistics`,).then( result => {
            statistics.athletes.maleCount = result.data.athletes.maleCount;
            statistics.athletes.femaleCount = result.data.athletes.femaleCount;
            statistics.labels = result.data.athletes.newCount.labels;
            statistics.athletes.newCount = result.data.athletes.newCount.series;
            statistics.athletes.lastMonthCount = result.data.athletes.lastMonthNewCount.series;
            statistics.dietPlans.newCount= result.data.dietPlans.newCount.series;
            statistics.dietPlans.lastMonthCount = result.data.dietPlans.lastCount.series;
            statistics.dietPlans.totalCount = result.data.dietPlans.totalCount;
            statistics.earnings.monthlyUntilToday = result.data.earnings.monthlyUntilToday;
            statistics.earnings.expectedTillEndOfMonth = result.data.earnings.expectedTillEndOfMonth;
            statistics.events.active = result.data.events.active;
            statistics.events.cancelled = result.data.events.cancelled;
            commit('CACHE_STATISTICS', statistics);
            commit('CACHE_STATISTICS_TIME', new Date());
            saveState('athletes.statistics', statistics);
            saveState('athletes.lastUpdated', state.lastUpdated);
        });

    },


    refreshCacheStatistics({commit, dispatch}){
        if(daysBetween(new Date(), new Date(state.lastUpdated)) >= 1){
            dispatch('cacheStatistics');
        }
        commit('CACHE_STATISTICS', getSavedState('athletes.statistics'));
        commit('CACHE_STATISTICS_TIME', getSavedState('athletes.lastUpdated'))
    },

    reset({commit}){
        commit('CACHE_STATISTICS', null);
        commit('CACHE_STATISTICS_TIME', null);
        commit('CACHE_ACTIVE_ATHLETES', null);
        commit('CACHE_ATHLETE_NAMES', []);
        commit('CACHE_LAST_ATHLETE_CACHE', null);

    }
};

// ===
// Private helpers
// ===

function daysBetween(date1, date2) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
}

function minutesBetween(date1, date2) {
    // The number of milliseconds in one day
    const ONE_MINUTE = 1000 * 60;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_MINUTE);
}
