<template>

  <div id="tools-base">
    <div class="row">
      <div class="col-md-4 tools-sidebar" style="max-height:500px;overflow-y:scroll">
        <ul style="padding-left:0;list-style-type:none;">
          <li v-for="tool of tools" :key="tool.slug">
            <div class="tools-navigation" :class=" selectedTool === tool.slug ? 'active' : ''" @click="selectTool(tool.slug)">{{ tool.title }}</div>
          </li>
        </ul>
      </div>
      <div class="col-md-8">
        <component :is="getComponent" class="pl-2 pb-2 pr-2 pt-0"></component>
      </div>
    </div>
  </div>

</template>

<style lang="scss">
#tools-base{
  .tools-navigation{
    padding:5px 10px;
    font-size:1em;
    color:#242424;
    cursor:pointer;
    border-radius:4px;
    &:hover{
      background-color:#d1d1d1;

    }
    &.active{
      background-color:#d4d4d4;
    }
  }
  .tools-sidebar{
    border-right: 1px solid #f3f3f3;
  }
}
</style>
<script>
import KidsBmi from './kids-bmi'
import AdultBmi from './adult-bmi'
import insulineResistance from './insuline-resistance'
import dailyFiber from './daily-fiber'
import gfrQuation from "./gfr-equation";
import hamwi from "@components/tools/hamwi.vue";
export default{

  components: {KidsBmi},
  data(){
    return{
      selectedTool: 'kids-bmi',
      tools: [
        {
          title:this.$t('tools.cole.title'),
          slug: 'kids-bmi',
          component: KidsBmi
        },
        {
          title: this.$t('tools.bmi.menu'),
          slug: 'bmi-adult',
          component: AdultBmi
        },
        {
          title: this.$t('tools.insulin.menu'),
          slug: 'insuline-resistance',
          component: insulineResistance
        },
        {
          title: this.$t('tools.fiber.menu'),
          slug: 'daily-fiber',
          component: dailyFiber
        },
        {
          title: this.$t('tools.gfr.menu'),
          slug: 'gfr-equation',
          component: gfrQuation
        },
        {
          title: this.$t('tools.hamwi.menu'),
          slug: 'hamwi',
          component: hamwi
        }
      ]
    }
  },
  computed:{
    getComponent(){
      return this.tools.find(x => this.selectedTool === x.slug) ? this.tools.find(x => this.selectedTool === x.slug).component : null
    }
  },
  created(){
    this.selectedTool = localStorage.getItem('tools.selectedTool') ? localStorage.getItem('tools.selectedTool') : 'kids-bmi'
  },

  methods:{
    selectTool(tool){
      this.selectedTool = tool;
      localStorage.setItem('tools.selectedTool', tool);
    }
  }
}
</script>
