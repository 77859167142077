<template>
  <div id="gfr-equation">
    <h5>{{ $t('tools.hamwi.title') }}</h5>
    <span class="text-muted">{{ $t('tools.hamwi.subtitle') }}</span>

    <div class="row mt-2">
      <div class="col-md-12 px-0">
        <b-form-group :label="$t('tools.cole.gender')" label-for="edit-name">
          <b-form-select v-model="gender">
            <option value="M">{{ $t('forms.fields.gender.male') }}</option>
            <option value="F">{{ $t('forms.fields.gender.female') }}</option>
          </b-form-select>
        </b-form-group>

        <b-form-group :label="$t('tools.weight')" label-for="edit-g">
          <b-input-group :append="$t('tools.append.weight')" >
            <b-form-input id="edit-g" v-model="kg" type="text"
                          :placeholder="$t('tools.bmi.placeholder.weight')"
                          @input="kg = replaceCommaWithDot(kg)"></b-form-input>
          </b-input-group>
        </b-form-group>


        <b-form-group :label="$t('tools.height')" label-for="edit-oro">
          <b-input-group :append="$t('tools.append.height')" >
            <b-form-input id="edit-oro" v-model="height" type="text"
                          :placeholder="$t('tools.bmi.placeholder.height')"
            />
          </b-input-group>
        </b-form-group>

        <div class="text-right">
          <b-button variant='primary' @click="calculate">
            {{$t('tools.gfr.btn')}}
          </b-button>
        </div>

        <div v-if="result" class="mt-3 px-0 tw-text-base border-top pt-3">

          <div>{{ $t('tools.hamwi.ideal') }}: <strong>{{ result.idealWeight }} {{ $t('tools.append.weight') }}</strong> </div>
          <div>{{ $t('tools.hamwi.adjusted') }}: <strong>{{ result.adjustedWeight }} {{$t('tools.append.weight')}}</strong> </div>
        </div>

      </div>

    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      gender: 'M',
      height: null,
      result: null,
      kg: null,
    }
  },
  methods:{
    calculate(){
      let idealWeight;
      if (this.gender === 'M') {
        idealWeight = 48 + 1.06 * (this.height - 152);
      } else {
        idealWeight = 45.5 + 0.89 * (this.height - 152.4);
      }
      idealWeight = +idealWeight.toFixed(2);

      const adjustedWeight = +(idealWeight + 0.25 * (this.kg - idealWeight)).toFixed(2);

      this.result = {
        idealWeight,
        adjustedWeight
      }
    }
  }
}

</script>
