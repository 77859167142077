import errors from "./errors";
import success from "./success";
import components from './components/index';
import diet_plan from "./diet_plan"
import routes from "./routes";
import pages from "./pages";
import global from "./global";
import forms from "./forms";
import educational from "./educational";
import topbar from "./topbar";
import autoGeneration from "./autoGeneration";
import dietPlans from "./dietPlans";
import dietPlan from "./dietPlan";
import food from "./food";
import footer from "./footer";
import auth from "./auth";
import chat from "./chat";
import tools from "./tools";
import cloud from "./cloud";
import calendar from "./calendar";
import pathisis from "./pathisis";
import massImport from "./massImport";
import admin from "./admin"
import billing from "./billing";
import shared from "./shared"
import inner from "./inner"

export default {
    topbar,
    errors,
    success,
    components,
    pages,
    diet_plan,
    routes,
    global,
    forms,
    educational,
    footer,
    dietPlans,
    dietPlan,
    autoGeneration,
    food,
    auth,
    chat,
    tools,
    cloud,
    calendar,
    pathisis,
    massImport,
    admin,
    billing,
    shared,
    inner,
    close: 'Κλείσιμο',
    dietitian: 'Διαιτολόγος',
    copied: 'Έχει αντιγραφεί',
    minute: 'λεπτό',
    minutes: 'λεπτά',
    next: 'Επόμενο',
    back: 'Πίσω',
    click: 'κάντε κλικ εδώ',
    select: 'Κάντε μια επιλογή',
    home: 'Αρχική σελίδα',
    today: 'Σήμερα',
    month: 'Μήνας',
    week: 'Εβδομάδα',
    day: 'Ημέρα',
    meal: 'Γεύμα',
    timeGrid: 'Ημέρα',
    beta: 'Δοκιμαστική έκδοση',
    save: 'Αποθήκευση',
    skipCrop: 'Διατήρηση ως έχει',
    crop: 'Περικοπή εικόνας',
    saveChanges: 'Αποθήκευση αλλαγών',
    create: 'Δημιουργία',
    notes: 'Σημειώσεις',
    insertNotes: 'Εισάγετε σημειώσεις...',
    datePickerPlaceholders: {
        date: "Επιλογή ημερομηνίας:",
        range: "Επιλογή εύρους ημερομηνιών",
        nextSeven: 'επόμενες 7 ημέρες',
        nextThirty: 'επόμενες 30 ημέρες',
        prevSeven: 'προηγούμενες 7 ημέρες',
        prevThirty: 'προηγούμενες 30 ημέρες'
    },
    optional: 'Προαιρετικό',
    delete: 'Διαγραφή',
    search: 'Αναζήτηση',
    searchPlaceholder: 'Αναζήτηση...',
    actions: 'Ενέργειες',
    backToLastPage: 'Επιστροφή στην τελευταία σελίδα',
    verifyDeletion: {
        title: 'Θέλετε σίγουρα να προχωρήσετε σε διαγραφή;',
        message: '<p>Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσουμε με κανέναν τρόπο να επαναφέρουμε τα δεδομένα σας.' +
            '</p> <p><strong>Θέλετε σίγουρα να προχωρήσετε;</strong></p>',
        ok: 'Ναι, διαγραφή',
        cancel: 'Όχι, ακύρωση'
    },
    cancel: 'Ακύρωση',
    days: {
        monday: 'Δευτέρα',
        tuesday: 'Τρίτη',
        wednesday: 'Τετάρτη',
        thursday: 'Πέμπτη',
        friday: 'Παρασκευή',
        saturday: 'Σάββατο',
        sunday: 'Κυριακή',
        short: {
            monday: 'Δευ.',
            tuesday: 'Τρ.',
            wednesday: 'Τετ.',
            thursday: 'Πέμ.',
            friday: 'Παρ.',
            saturday: 'Σάβ.',
            sunday: 'Κυρ.',
        }
    },
    meals: {
        breakfast: 'Πρωϊνό',
        midBreakfast: 'Δεκατιανό',
        lunch: 'Μεσημεριανό',
        afterLunch: 'Απογευματινό',
        dinner: 'Βραδινό',
    },
    counts: {
        first: '1st',
        second: '2nd',
        third: '3rd',
        fourth: '4th',
    },
    nth: '{num}η',
    nthChoice: '{num}η Επιλογή',
    nthChoiceShort: '{num}η Επ.',
    months: {
        short: {
            january: 'Ιαν',
            february: 'Φεβ',
            march: 'Μαρ',
            april: 'Απρ',
            may: 'Μαϊος',
            june: 'Ιουν',
            july: 'Ιουλ',
            august: 'Αυγ',
            september: 'Σεπ',
            october: 'Οκτ',
            november: 'Νοε',
            december: 'Δεκ'
        }
    },
    name: 'Όνομα',
    templateName: 'Όνομα προτύπου',
    short: {
        quantity: 'Ποσ'
    },
    quantity: 'Ποσότητα',
    micro: {
        carbs: 'Υδατάνθρακες',
        proteins: 'Πρωτεϊνες',
        fats: 'Λίπη',
        cal: 'Ενέργεια',
        percentage: {
            carbs: 'Ποσοστό υδατανθράκων',
            proteins: 'Ποσοστό πρωτεϊνών',
            fats: 'Ποσοστό λίπους',
        },
        short: {
            carbs: 'Υδατ.',
            proteins: 'Πρωτ.',
            fats: 'Λίπη',
            cal: 'Ενέρ.',
        }
    },
    show: 'Εμφάνιση',
    hide: 'Απόκρυψη',
    date: 'Ημερομηνία',
    google_calendar_redirect: {
        title: 'Η σελίδα αυτή είναι μόνο για τον διατροφολόγο σας',
        text: 'Ο σύνδεσμος που ακολουθήσατε είναι η καρτέλα των στοιχείων σας και είναι μόνο προσβάσιμη από τον διατροφολόγο σας.' +
            'Εάν επιθυμείτε να χρησιμοποιήσετε το mobile app μας, παρακαλώ επικοινωνήστε με τον διατροφολόγο σας για οδηγίες!',
        dietitian: 'Είστε ο διατροφολόγος και δεν έχετε συνδεθεί;',
        button: 'Πατήστε εδώ για να συνδεθείτε'
    },
    shareACloud: 'Κοινοποίηση στο aCloud',
    aCloudCantReShare: 'Δεν μπορείτε να ξανακοινοποίησετε στο athlisis cloud.',
    aCloudViewLive: 'Προβολή του cloud πλάνου',
    copy: 'Αντιγραφή',
    paste: 'Επικόλληση',
    clear: 'Εκκαθάριση',
    editMeal: 'Επεξεργασία γεύματος',
    copyText: 'Αντιγραφή σε νέο',
    list: 'Λίστα',
    foods: 'Τρόφιμα',
    mealTemplates: {
        title: 'Πρότυπα γεύματα',
        kind: 'Είδος',
        free: 'Ελεύθερο πρότυπο',
        chooseType: 'Επιλέξτε τύπο',
        chooseKind: 'Επιλέξτε είδος',
        empty: 'ΔΕΝ ΒΡΕΘΗΚΑΝ ΠΡΟΤΥΠΑ ΓΕΥΜΑΤΑ',
        emptyText: 'Δεν έχετε αποθηκεύσει ακόμα κάποιο πρότυπο γεύμα.'
    },
    chooseCustomer: 'Επιλογή πελάτη',
    copyToTemplate: 'Αντιγραφή σε νέο πρότυπο',
    copyToAthlete: 'Αντιγραφή σε πελάτη',
    copyTemplate: 'Αντιγραφή προτύπου',
    saveTemplate: 'Αποθήκευση ως πρότυπο',
    importTemplate: 'Εισαγωγή από πρότυπο',
    sendByMail: 'Αποστολή με e-mail',
    emailSubject: 'Θέμα e-mail',
    description: 'Περιγραφή',
    aCloudName: 'Το όνομα που θα υπάρχει στο cloud',
    chooseTagsACloud: 'Επιλέξτε ετικέτες (τουλάχιστον 1)',
    reset: 'Επαναφορά',
    total: 'Σύνολο',
    saveAsCsv: 'Αποθήκευση ως CSV',
    isOffline:'Δεν έχετε πρόσβαση στο Internet',
    backOnline: 'Η σύνδεση στο Internet αποκαταστάθηκε',
    sent: 'Έχει αποσταλεί',
    safariInstructions: '     Επειδή χρησιμοποιείτε Safari, <a\n' +
        '        href="https://athlisis-new.s3.eu-north-1.amazonaws.com/athlisis_safari_print_e2dbe5442e.pdf" target="_blank"\n' +
        '    >\n' +
        '      παρακαλώ ακολουθήστε τις οδηγίες για ορθή εκτύπωση κάνοντας κλικ εδώ.\n' +
        '    </a>',
    tbw: 'TBW (Συνολικά υγρά σώματος)',
    weeklyExercise: 'Εβδομαδιαία άσκηση',
    templatePlan: 'Πρότυπο διατροφικό πλάνο',
    notifications: {
        title: 'Ενημερώσεις',
        noNotifications: 'Δεν υπάρχουν ειδοποιήσεις!'
    },
    eg: 'π.χ. {value}',
    measure: 'Μέτρηση',
    measures: {
        title: 'Μεζούρες',
        0: 'Κάντε μια επιλογή',
        short: {
            1: 'τεμ. (μεγ.)',
            2: 'τεμ. (μικ.)',
            3: 'τεμ.',
            4: 'φλ.',
            6: 'κ.γ.',
            7: 'κ.σ.',
            8: 'γρ.',
            9: 'φλ. τσ.',
            10: 'τεμ. (μετρ.)',
            11: 'ml',
            12: 'scoop',
            13: 'φέτα | φέτες'
        },
        long: {
            1: 'Τεμάχιο (μεγάλο)',
            2: 'Τεμάχιο (μικρό)',
            3: 'Τεμάχιο',
            4: 'Φλιτζάνι',
            6: 'Κουταλάκι γλυκού',
            7: 'Κουταλιά σούπας',
            8: 'Γραμμάρια',
            9: 'Φλιτζάνι τσαγιού',
            10: 'Τεμάχιο (μέτριο)',
            11: 'ml',
            12: 'Scoop',
            13: 'φέτα'
        }
    }
}
